import InputField from '@/components/forms/formFields/InputField';
import { useUserContext } from '@/contexts/user-context';
import api from '@/core/client';
import {
    Avatar,
    Box,
    Center,
    Flex,
    HStack,
    Icon,
    Menu,
    MenuButton,
    MenuList,
    Portal,
    Spinner,
    Text,
    useBoolean,
} from '@chakra-ui/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import React, { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiSearch } from 'react-icons/hi';
import { useInView } from 'react-intersection-observer';
import StoreName from '../store/StoreName';
import { motion } from 'framer-motion';
import { useContrastScheme } from '@/chakra/themeUtils';
import { useDebounce } from '@uidotdev/usehooks';

const DEBOUNCE_DELAY = 800;

const StoreSwitcher = () => {
    const [menuOpen, setMenuOpen] = useBoolean(false);
    const [mouseHover, setMouseHover] = useBoolean(false);
    const { selectStore, activeStore, isAdmin, user } = useUserContext();
    const { t } = useTranslation(['common']);
    const methods = useForm<{ searchTerm: string }>({
        defaultValues: { searchTerm: '' },
    });
    const searchTerm = methods.watch('searchTerm');
    const debouncedQuery = useDebounce(searchTerm, DEBOUNCE_DELAY);

    const {
        data: storePages,
        fetchNextPage,
        isFetching,
    } = useInfiniteQuery(
        ['users', user?.id, 'stores', debouncedQuery],
        async ({ pageParam = 1 }) => {
            const res = await (isAdmin
                ? api.stores.getStores({
                      page: pageParam,
                      limit: 20,
                      q: debouncedQuery,
                  })
                : api.dealers.getDealerPaginatedStores(user?.id ?? '', {
                      page: pageParam,
                      limit: 20,
                      q: debouncedQuery,
                  }));
            return res;
        },
        {
            enabled: !!user?.id,
            getPreviousPageParam: (firstPage) =>
                firstPage.meta.hasPreviousPage ? firstPage.meta.page - 1 : undefined,
            getNextPageParam: (lastPage) =>
                lastPage.meta.hasNextPage ? lastPage.meta.page + 1 : undefined,
        },
    );

    const searchFieldRef = useRef<HTMLInputElement>(null);

    const { ref, inView } = useInView({ delay: 100, rootMargin: '10px', threshold: 0.5 });

    useEffect(() => {
        if (inView && !isFetching) {
            fetchNextPage();
        }
    }, [inView, isFetching, fetchNextPage]);

    const animated = mouseHover && !menuOpen;
    const logoUrl = activeStore?.settings?.logoUrl;
    const contrastScheme = useContrastScheme('secondary.main');

    return (
        <Menu
            isLazy
            initialFocusRef={searchFieldRef}
            closeOnSelect
            placement="right-start"
            isOpen={menuOpen}
            onClose={setMenuOpen.off}
        >
            <MenuButton
                as={Box}
                onClick={setMenuOpen.on}
                my={2}
                cursor="pointer"
                width="38px"
                height="38px"
                position="relative"
                onMouseEnter={setMouseHover.on}
                onMouseLeave={setMouseHover.off}
            >
                {logoUrl ? (
                    <Flex
                        overflow="hidden"
                        borderRadius="md"
                        h="38px"
                        w="38px"
                        gap={4}
                        borderWidth="1px"
                        borderColor={`${contrastScheme}.300`}
                    >
                        <img
                            src={logoUrl}
                            alt={`${activeStore?.name}-logo`}
                            style={{
                                objectFit: 'cover',
                                height: '38px',
                                width: '38px',
                            }}
                        />
                    </Flex>
                ) : (
                    <Avatar
                        bg="primary.500"
                        color="primary.50"
                        h="38px"
                        size="sm"
                        w="38px"
                        name={activeStore?.name}
                    />
                )}
                <HStack
                    spacing={0}
                    alignItems="center"
                    position="absolute"
                    left="calc(100% + 4px)"
                    top="50%"
                    transform="translateY(-50%)"
                    borderRadius="md"
                    bg="primary.100"
                    color="primary.700"
                >
                    <motion.div
                        initial={false}
                        animate={{
                            opacity: animated ? 1 : 0,
                            width: animated ? 'auto' : 0,
                        }}
                    >
                        <Text fontWeight="medium" whiteSpace="nowrap" px={2} py={0.5}>
                            {activeStore?.name}
                        </Text>
                    </motion.div>
                </HStack>
            </MenuButton>

            <Portal>
                <MenuList backgroundColor="white" pb={0} overflow="hidden" w="250px">
                    <FormProvider {...methods}>
                        <Box
                            as="form"
                            onSubmit={methods.handleSubmit(() => {
                                if (storePages?.pages?.[0]?.data?.length) {
                                    selectStore(storePages.pages[0].data[0].id);
                                }
                            })}
                            p={2}
                        >
                            <InputField
                                size="md"
                                variant="plain"
                                formControlProps={{ mt: -2 }}
                                name="searchTerm"
                                prefixContent={<Icon as={HiSearch} />}
                                ref={searchFieldRef}
                                placeholder={t('common:search')}
                            />
                        </Box>
                    </FormProvider>
                    <Box overflowY="auto" maxHeight="300px">
                        {storePages &&
                            storePages.pages.map((page) =>
                                (page.data ?? []).map((store) => {
                                    if (store?.id === activeStore?.id) return null;
                                    const isActive = activeStore?.id === store.id;
                                    return (
                                        <Box
                                            bg={isActive ? 'primary.50' : undefined}
                                            color={isActive ? 'primary.600' : undefined}
                                            px={2.5}
                                            fontWeight={isActive ? 'bold' : undefined}
                                            py={1.5}
                                            cursor="pointer"
                                            onClick={() => {
                                                setMenuOpen.off();
                                                selectStore(store.id);
                                                methods.setValue('searchTerm', '');
                                            }}
                                            display="flex"
                                            alignItems="center"
                                            sx={{
                                                _hover: {
                                                    bg: isActive ? undefined : 'blackAlpha.50',
                                                },
                                            }}
                                            key={store.id}
                                            role="menuitem"
                                        >
                                            <StoreName store={store} />
                                        </Box>
                                    );
                                }),
                            )}
                        {isFetching && (
                            <Center h={50}>
                                <Spinner />
                            </Center>
                        )}
                        <Box h={5} ref={ref}></Box>
                    </Box>
                </MenuList>
            </Portal>
        </Menu>
    );
};

export default StoreSwitcher;
