import {
    Checkbox,
    Table as ChakraTable,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Icon,
    Box,
    Heading,
    Flex,
} from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import type { ColumnDef, Row, ExpandedState, SortingState } from '@tanstack/react-table';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getExpandedRowModel,
} from '@tanstack/react-table';
import type { FC } from 'react';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useToast from '@/hooks/useToast';
import { PiEmpty } from 'react-icons/pi';
import { WithLink } from '@/hoc/withLink';
import { useRouter } from 'next/router';
import { isFunction } from 'lodash';

export enum QueryOrder {
    DESC = 'DESC',
    ASC = 'ASC',
}

interface TableProps<TItem> {
    data?: TItem[];
    columns: ColumnDef<TItem, string>[];
    isLoading?: boolean;
    selection?: {
        selected: string[];
        onChange: (selected: string[]) => void;
        keyProperty: keyof TItem;
        maxSelected?: number;
    };
    expandedRow?: FC<{ row: Row<TItem> }>;
    rowHref?: (row: TItem) => string;
}

const SelectionTable = <TItem,>({
    columns,
    data = [],
    selection,
    expandedRow,
    rowHref,
}: TableProps<TItem>) => {
    const { t } = useTranslation('common');
    const toast = useToast();
    const isExpandable = !!expandedRow;
    const isSelectable = !!selection;

    const rowSelection = useMemo(
        () =>
            selection &&
            selection.selected.reduce((acc, id) => ({ ...acc, ...{ [id]: true } }), {}),
        [selection],
    );

    const [expanded, setExpanded] = useState<ExpandedState>({});

    const [sorting, setSorting] = useState<SortingState>([]);
    const router = useRouter();
    useEffect(() => {
        let sortingValue;
        if (router.query.order) {
            sortingValue = String(router.query.order).split(',')[0].split(' ');
        }
        if (sortingValue) {
            setSorting([{ id: sortingValue[0], desc: sortingValue[1] === QueryOrder.DESC }]);
        } else {
            setSorting([]);
        }
    }, [router.query.order]);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: isExpandable ? getExpandedRowModel() : undefined,
        onExpandedChange: isExpandable ? setExpanded : undefined,
        getRowCanExpand: () => isExpandable,
        enableRowSelection: isSelectable,
        onSortingChange: (updater) => {
            const update = isFunction(updater) ? updater(sorting) : updater;
            const [primary] = update;
            let currentValue = String(router.query.order ?? '').split(',') || [];
            if (primary) {
                currentValue = currentValue.filter(
                    (elem: string) => elem.split(' ')[0] !== primary?.id,
                );
            } else {
                currentValue.shift();
            }
            const orderValue = [
                update.map((itm) => `${itm.id} ${itm.desc ? QueryOrder.DESC : QueryOrder.ASC}`),
                ...currentValue,
            ].filter((str) => str !== '');
            router.replace(
                {
                    query: { ...router.query, order: orderValue.join(',') },
                },
                undefined,
                { scroll: false },
            );
        },
        onStateChange: isSelectable
            ? (updater) => {
                  const maxSelected = selection.maxSelected;
                  const update =
                      typeof updater === 'function' ? updater(table.getState()) : updater;

                  const selectedRows = Object.keys(update.rowSelection);
                  if (maxSelected && selectedRows.length > maxSelected) {
                      toast({
                          title: t('max_selected', { max: maxSelected }),
                          status: 'warning',
                      });
                      const selectedKeys = selectedRows.slice(0, maxSelected);
                      selection.onChange(selectedKeys);
                  } else {
                      selection.onChange(selectedRows);
                  }
              }
            : undefined,
        getRowId: isSelectable
            ? (row, relativeIndex, parent) =>
                  parent
                      ? [parent.id, row[selection.keyProperty]].join('.')
                      : (row[selection.keyProperty] as string)
            : undefined,
        state: {
            expanded,
            rowSelection,
            sorting,
        },
    });

    return (
        <TableContainer>
            <ChakraTable>
                <Thead>
                    {table.getHeaderGroups().map((headerGroup) => {
                        return (
                            <Tr key={headerGroup.id}>
                                {isSelectable && (
                                    <Th>
                                        <Checkbox
                                            isChecked={table.getIsAllRowsSelected()}
                                            onChange={table.getToggleAllRowsSelectedHandler()}
                                        />
                                    </Th>
                                )}
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <Th
                                            key={header.id}
                                            onClick={(e) => {
                                                if (
                                                    header.column.columnDef.enableSorting === true
                                                ) {
                                                    e.preventDefault();
                                                    header.column.getToggleSortingHandler()?.(e);
                                                }
                                            }}
                                            cursor={
                                                header.column.columnDef.enableSorting === true
                                                    ? 'pointer'
                                                    : 'default'
                                            }
                                            gap={2}
                                        >
                                            <Flex
                                                direction={'row'}
                                                justifyContent={'space-between'}
                                            >
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                          header.column.columnDef.header,
                                                          header.getContext(),
                                                      )}

                                                {header.column.columnDef.enableSorting === true && (
                                                    <Flex direction={'column'}>
                                                        <TriangleUpIcon
                                                            color={
                                                                header.column.getIsSorted() ===
                                                                'asc'
                                                                    ? 'black'
                                                                    : 'gray.400'
                                                            }
                                                            fontSize="10px"
                                                        />
                                                        <TriangleDownIcon
                                                            color={
                                                                header.column.getIsSorted() ===
                                                                'desc'
                                                                    ? 'black'
                                                                    : 'gray.400'
                                                            }
                                                            fontSize="10px"
                                                        />
                                                    </Flex>
                                                )}
                                            </Flex>
                                        </Th>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map((row) => (
                        <Fragment key={row.id}>
                            <Tr
                                clipPath={'inset(0)'} // Hack for Safari (relative on tr does not work) https://github.com/w3c/csswg-drafts/issues/1899
                                position={'relative'}
                                _hover={{
                                    backgroundColor: 'gray.50',
                                }}
                            >
                                {isSelectable && (
                                    <Td position={'relative'}>
                                        <Checkbox
                                            isChecked={row.getIsSelected()}
                                            onChange={row.getToggleSelectedHandler()}
                                        />
                                    </Td>
                                )}
                                {row.getVisibleCells().map((cell, index) => {
                                    const triggerMainLink =
                                        cell.column.columnDef.meta?.triggerMainLink ?? true;
                                    const isFirstColumn = index === 0 && !isSelectable;
                                    const rowHrefValue = rowHref?.(row.original);
                                    const isMainClickable =
                                        isFirstColumn && rowHrefValue && triggerMainLink;
                                    return (
                                        <Td
                                            key={cell.id}
                                            position={triggerMainLink ? undefined : 'relative'}
                                        >
                                            <WithLink
                                                link={isMainClickable ? rowHrefValue : undefined}
                                            >
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext(),
                                                )}
                                            </WithLink>
                                        </Td>
                                    );
                                })}
                            </Tr>
                            {isExpandable && row.getIsExpanded() && (
                                <Tr>
                                    <Td colSpan={row.getAllCells().length}>
                                        {expandedRow({ row })}
                                    </Td>
                                </Tr>
                            )}
                        </Fragment>
                    ))}
                    {/* Empty table */}
                    {table.getRowModel().rows.length === 0 && (
                        <Tr>
                            <Td
                                color="gray.500"
                                textAlign="center"
                                colSpan={table.getVisibleFlatColumns().length}
                                pt={8}
                            >
                                <Box
                                    p={3}
                                    bgColor={'gray.200'}
                                    width={'fit-content'}
                                    margin={'auto'}
                                    borderRadius={6}
                                >
                                    <Icon as={PiEmpty} fontSize="xl" display={'flex'} />
                                </Box>
                                <Heading
                                    flexShrink={0}
                                    fontSize={'xl'}
                                    fontWeight="bold"
                                    color={'gray.800'}
                                >
                                    {t('no_search_data_found')}
                                </Heading>
                            </Td>
                        </Tr>
                    )}
                </Tbody>
            </ChakraTable>
        </TableContainer>
    );
};

export default SelectionTable;
